import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme: { space } }) => css`
    @media (max-width: 500px) {
      button.buttonAgendamento {
        height: 43px;
        min-width: 158px;
        width: 158px !important;
        max-width: 158px;
        position: relative;
        z-index: 2;

        color: #eee;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 166.667% */
        letter-spacing: 0.36px;
      }
    }
  `}
`;

export const SectioStarCheck = styled.div`
  ${({ theme: { space } }) => css`
    background: #eee;
    height: 744px;
    display: flex;
    width: 100%;

    > div {
      flex: 1;
    }

    @media (max-width: 500px) {
      background-color: #fff;
      height: 562px;
      flex-direction: column;
    }
  `}
`;

export const Title = styled.h2`
  color: #111;
  font-family: Poppins;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 111.111% */
  margin-bottom: 24px;

  span {
    display: block;
  }

  @media (max-width: 500px) {
    color: #111;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 100% */

    img {
      width: 25px;
    }
  }
`;

export const SubTitle = styled.h3`
  color: #111;
  text-align: center;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
`;

export const Textplus = styled.p`
  color: #2d2d2d;
  text-align: center;
  font-family: "Roboto";
  font-size: 1.22rem;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-top: 24px;
  margin-bottom: 48px;

  span {
    margin-right: 5px;
  }
`;

export const SectioStarCheckLeftSide = styled.div`
  background: radial-gradient(
    62.89% 62.62% at 50% 50%,
    #002fd3 10.82%,
    #00186d 100%
  );

  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    height: fit-content;
  }
`;

export const SectioStarCheckLeftSideContent = styled.div`
  max-width: 600px;
  width: 100%;
  margin-left: auto;

  > h1 {
    color: #fff;
    font-family: Poppins;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px; /* 111.111% */
    margin-bottom: 40px;

    span {
      display: block;
    }

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
        hue-rotate(108deg) brightness(104%) contrast(104%);
    }
  }

  > p {
    color: #fff;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    max-width: 430px;
    margin-bottom: 40px;
  }

  @media (max-width: 500px) {
    padding: 32px 20px 90px;

    h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      display: block;
    }

    p {
      color: #fff;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      line-height: 26px;
      max-width: 312px;
    }

    img {
      width: 24px;
    }
  }
`;

export const SectioStarCheckRightSide = styled.div``;

export const SectioStarCheckRightSideContent = styled.div`
  max-width: 600px;
  width: 100%;
  position: relative;
  margin-right: auto;
  height: 100%;

  img {
    position: absolute;
    bottom: 0;
    left: -360px;
  }

  @media (max-width: 500px) {
    img {
      bottom: 0;
      right: 0;
      left: auto;
    }
  }
`;

export const SectionAbout = styled.div`
  padding: 120px 0;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const SectionAboutContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    margin: 0 auto;

    h1 {
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
          saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);
      }
    }
    button {
      display: block;
      margin: 70px auto;
    }
  `}
`;

export const TextBlue = styled.span`
  color: #266bf0;
  font-weight: 700;
`;

export const TextDefault = styled.p`
  margin-bottom: 24px;
  color: #2d2d2d;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;

export const TextStrong = styled.span`
  font-weight: 700;
`;

export const SectionCards = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperCard = styled.div`
  width: max-content;

  h3 {
    color: #111;
    text-align: center;
    font-family: "Poppins";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 40px;
  }

  h4 {
    color: #eee;
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
  }
`;

export const Grid = styled.div<{ $gridTemplate: string; $gap?: string }>`
  ${({ $gridTemplate, $gap }) => css`
    display: grid;
    grid-template-columns: ${$gridTemplate};
    gap: ${$gap};

    img {
      display: block;
      width: 20px;
      margin-top: 6px;
    }
  `}
`;

export const Flex = styled.div<{
  gap?: string;
  justifyContent?: string;
  alignItems?: string;
}>`
  ${({ alignItems, justifyContent, gap }) => css`
    display: flex;
    align-items: ${alignItems};
    justify-content: ${justifyContent};
    gap: ${gap};
  `}
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 360px;
  padding: 0 18px;
  border-radius: 5px;
  background: linear-gradient(
    217deg,
    #00186d -10.67%,
    #002ed1 -10.66%,
    #00114d 78.45%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  p {
    color: #eee;
    text-align: center;
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
`;

export const SectionServices = styled.div`
  ${({ theme: { space } }) => css`
    /* max-width: ${space.large}; */
    background-color: #eee;
    padding: 80px 0;
    margin: 0 auto;
    width: 100%;

    /* @media (max-width: 500px) {
      margin-top: 100px;
    } */
  `}
`;

export const SectionServicesContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    margin: 0 auto;
    text-align: center;

    button {
      margin: 0 auto;
    }

    @media (max-width: 500px) {
      padding: 0 24px;

      > p {
        font-size: 16px;
      }
    }
  `}
`;

export const TableService = styled.table`
  thead {
    background: #00114d;
  }

  tbody tr:nth-child(3) {
    background: #00114d;
  }

  width: 1005px;
  margin: 60px auto;
  height: 590px;
  border-collapse: collapse;

  border-radius: 5px;
  background: linear-gradient(
    221deg,
    #00186d -7.87%,
    #002ed1 -7.85%,
    #00114d 109.87%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  td {
    h3 {
      color: #eee;
      text-align: center;
      font-family: "Lato";
      font-size: 48px;
      display: flex;
      justify-content: center;
      gap: 10px;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 1.44px;

      span {
        color: #ed0000;
      }
    }
  }

  tr {
    text-align: center;
    height: 98px;
  }

  tbody td:nth-child(1) + td {
    width: 50%;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  tbody td:nth-child(1) {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;

    color: #eee;
    text-align: center;
    font-family: "Roboto";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  @media (max-width: 500px) {
    width: 100%;

    td > p {
      font-size: 16px;
      max-width: 110px;
      text-align: left;
      margin: 0 auto;
    }

    td > h3 {
      font-size: 28px;
      gap: 6px;
    }
  }
`;

export const TableWrapperTitle = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 auto;
  gap: 0 10px;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    width: 300px;
  }
`;

export const TitleTable = styled.h2`
  color: #eee;

  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;

  @media (max-width: 500px) {
    font-size: 18px;
    width: fit-content;
    line-height: 1.6;
    text-align: start;
    /* white-space: nowrap; */

    span {
      display: block;
    }
  }
`;

export const SectionLocation = styled.div`
  ${({ theme: { space } }) => css`
    padding: 120px 0;

    @media (max-width: 500px) {
      padding: 50px 0;
    }
  `}
`;

export const SectionLocationContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    margin: 0 auto;
    text-align: center;

    padding: 0 24px;
  `}
`;

export const SectionLocationWrapperCardsMap = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    padding: 80px 0;
    display: flex;
    gap: 70px;
    flex-wrap: wrap;
    justify-content: center;
  `}
`;

export const TitleLocalization = styled.h2`
  display: flex;
  color: #111;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  span {
    text-align: left;
  }
`;
export const SectionLocationCardMap = styled.div`
  h2 {
    justify-content: center;
  }

  p {
    color: #2d2d2d;
    text-align: center;
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    margin-top: 24px;
  }
`;

export const WrapperImgMap = styled.a`
  position: relative;
  display: block;
  cursor: pointer;
  margin-bottom: 60px;

  &::before {
    content: "";
    top: -18px;
    right: -20px;
    position: absolute;
    display: block;
    width: 257.37px;
    height: 165.42px;
    border-radius: 5px;
    background: #00186d;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  &::after {
    content: "";
    bottom: -10px;
    left: -20px;
    position: absolute;
    display: block;
    width: 257.37px;
    height: 165.42px;
    border-radius: 5px;
    background: #266bf0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  img {
    width: 320px;
    position: relative;
    z-index: 1;
  }
`;

export const SectionContact = styled.section`
  padding: 150px 0;
  background: #266bf0;
  min-height: 216px;

  @media (max-width: 500px) {
    padding: 50px 0;
  }
`;

export const SectionContactContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    h1 {
      color: #efefef;
      font-family: "Poppins";
      font-size: 72px;
      font-style: normal;
      font-weight: 700;
      line-height: 80px;

      align-self: center;
    }

    div {
      align-self: self-end;
      p {
        color: #eee;
        font-family: "Poppins";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        margin-top: 10px;
      }
    }
  `}

  @media (max-width: 500px) {
    padding: 0 24px;

    flex-direction: column;

    > div {
      width: 210px;
      align-self: center;
    }

    > div + div {
      margin-top: 16px;
    }

    h1 {
      font-size: 36px;
      text-align: center;
      width: 100%;
      margin-bottom: 36px;

      img {
        width: 26px;
      }
    }
  }
`;

export const TextRed = styled.span`
  color: #ed0000;
`;
