import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export const useCookieBannerPage = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get("cookie-consent");

    if (!cookieConsent) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookie-consent", "true", { expires: 365 });
    setIsVisible(false);
  };

  return {
    isVisible,
    handleAccept,
  };
};
