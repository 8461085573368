import styled, { keyframes } from "styled-components";

export const slideDown = keyframes`
  from {
    transform: translate(-50%, -100%);
  }
  to {
    transform: translate(-50%, 0);
  }
`;

export const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0px 0px 10px 10px;
  background: #fff;
  padding: 16px 24px;
  width: 100%;
  max-width: 1000px;
  font-family: Roboto;

  display: ${(props) => (props.visible ? "block" : "none")};
  animation: ${slideDown} 0.5s ease-in-out;

  p {
    color: #2d2d2d;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  > p + p {
    margin-top: 16px;
  }

  a {
    color: #266bf0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  @media (min-width: 1024px) {
    padding: 48px 100px;

    p {
      font-size: 20px;
    }

    a {
      font-size: 20px;
    }
  }
`;

export const WrapperButtons = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 36px;

  > button {
    display: flex;
    height: 44px;
    padding: 10px;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    background: linear-gradient(0deg, #266bf0 31.39%, #558fff 139.66%);

    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }
`;
