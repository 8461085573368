import React from "react";
import * as S from "./styles";
import { useFooter } from "./useFooter";

export const Footer = () => {
  const { navigate } = useFooter();
  return (
    <S.Footer>
      <S.FooterContent>
        <S.FooterFirstDiv>
          <img src="/assets/imgs/logo-starcheck02.svg" alt="logo starcheck" />
        </S.FooterFirstDiv>
        <S.FooterSecondDiv>
          <S.FooterSecondDivContent>
            <S.TitleSectionFooter>Fale conosco</S.TitleSectionFooter>
            <div>
              <img src="/assets/imgs/email-icon.svg" alt="" />
              <p>ouvidoria@starcheckvistoria.com</p>
            </div>
          </S.FooterSecondDivContent>

          <div>
            <S.TitleSectionFooter>Redes Sociais</S.TitleSectionFooter>

            <S.WrapperSocialIconsFooter>
              <a href="#">
                <img
                  src="/assets/imgs/facebook-icon.svg"
                  alt="icone facebook"
                />
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/imgs/instagram-icon.svg"
                  alt="icone instagram"
                />
              </a>
              <a href="#">
                <img src="/assets/imgs/youtube-icon.svg" alt="icone youtube" />
              </a>
            </S.WrapperSocialIconsFooter>
          </div>
        </S.FooterSecondDiv>
        <S.FooterThirdDiv>
          <S.TitleSectionFooter>
            Confira os itens verificados
          </S.TitleSectionFooter>

          <S.LinkDownDoc
            href="/assets/docs/Checklist-detran-starcheck.pdf"
            download
          >
            <img src="/assets/imgs/icon-checklist.svg" alt="icone checklist" />
            <span>Baixar Checklist</span>
          </S.LinkDownDoc>
          {/* <S.TitleSectionFooter>Redes Sociais</S.TitleSectionFooter>

          <S.WrapperSocialIconsFooter>
            <a href="#">
              <img src="/assets/imgs/facebook-icon.svg" alt="icone facebook" />
            </a>
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/imgs/instagram-icon.svg"
                alt="icone instagram"
              />
            </a>
            <a href="#">
              <img src="/assets/imgs/youtube-icon.svg" alt="icone youtube" />
            </a>
          </S.WrapperSocialIconsFooter> */}
        </S.FooterThirdDiv>
      </S.FooterContent>
      <S.FooterBar>
        <S.FooterBarContent>
          <h3>Direitos Autorais © 2023 StarCheck</h3>
          <div className="wrapperButtons">
            <button onClick={() => navigate("/termos-de-uso")}>
              Termos de Uso
            </button>
            <span>|</span>
            <button onClick={() => navigate("/politica-de-privacidade")}>
              Política de Privacidade
            </button>
          </div>
        </S.FooterBarContent>
      </S.FooterBar>
    </S.Footer>
  );
};
